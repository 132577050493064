import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Link, Icon } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';

const PendingWithdrawals = ({ count, amount }) => count > 0 && (
  <div className="qd-pending-withdrawals">
    <div className="qd-pending-withdrawals__title">
      -
      {' '}
      {t('quick_deposit.return_withdrawals')}
      {' '}
      -
    </div>
    {/* TODO: Content below is similar in asia cashier and cashier actions, extract */}
    <Link to="/bv_cashier/withdraw/pending" className="pending-withdraws__link">
      {t('pending_withdrawals')}
      <span className="pending-withdraws__amount">
        {toCurrency(amount)}
      </span>
      <span className="pending-withdraws__counter">{count}</span>
      <Icon id="arrow-right" />
    </Link>
  </div>
);

PendingWithdrawals.propTypes = {
  count: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
};

export default PendingWithdrawals;
