import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { getAmount, pushDepositEvent } from 'CashierCommon/helpers';
import CashierFormFrameView from './cashier_form_frame_view';

const CashierFormFrameContainer = ({
  url, parameters, setResult, integrationAdditionalInfo,
}) => {
  // Effect to submit the form on mount
  const formRef = useRef(null);
  useEffect(() => {
    formRef.current?.submit();
  }, []);

  // Effect to listen to iframe messages
  const iframeRef = useRef(null);
  useEffect(() => {
    const onMessage = (event) => {
      if (event.source === iframeRef.current.contentWindow && event.data.source === 'transactionResult') {
        pushDepositEvent(event.data.parameters, true);
        setResult(event.data.parameters);
      }
    };

    window.addEventListener('message', onMessage);

    return () => { window.removeEventListener('message', onMessage); };
  }, []);

  const {
    lastDeposit,
    minAmount,
    ...restParameters
  } = parameters;

  const { amount } = useParams();

  return (
    <CashierFormFrameView
      url={url}
      params={{
        ...restParameters,
        minAmount,
        amount: getAmount(minAmount, amount, lastDeposit),
      }}
      formRef={formRef}
      iframeRef={iframeRef}
      setResult={setResult}
      integrationAdditionalInfo={integrationAdditionalInfo}
    />
  );
};

CashierFormFrameContainer.propTypes = {
  url: PropTypes.string.isRequired,
  parameters: PropTypes.instanceOf(Object).isRequired,
  setResult: PropTypes.func.isRequired,
  integrationAdditionalInfo: PropTypes.instanceOf(Object),
};

CashierFormFrameContainer.defaultProps = {
  integrationAdditionalInfo: {},
};

export default CashierFormFrameContainer;
