import PropTypes from 'prop-types';
import { FeatureGuardComponent } from 'bv-components';
import SaferGamblingMessage from 'CashierActions/components/safer_gambling_message';
import SecureTransaction from 'SharedComponents/cashier/secure_transaction';

import { CashierForm, PendingWithdrawals } from './components';

const QuickDepositView = ({ session, pendingWithdrawals }) => (
  <div className="quick-deposit__body">
    <CashierForm {...session} />
    <PendingWithdrawals {...pendingWithdrawals} />
    <FeatureGuardComponent feature="deposit_safer_gambling_message">
      <SaferGamblingMessage />
    </FeatureGuardComponent>
    <SecureTransaction />
  </div>
);

QuickDepositView.propTypes = {
  session: PropTypes.shape({
    url: PropTypes.string.isRequired,
    parameters: PropTypes.instanceOf(Object).isRequired,
  }).isRequired,
  pendingWithdrawals: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
  }).isRequired,
};

export default QuickDepositView;
