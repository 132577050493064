import PropTypes from 'prop-types';
import { useState } from 'react';
import { Spinner } from 'bv-components';
import { findFeMethod } from 'SharedComponents/cashier/fe_deposit';
import FeMethodLoader from '../fe_method_loader';

const CashierFormFrameView = ({
  url,
  params,
  formRef,
  iframeRef,
  setResult,
  integrationAdditionalInfo,
}) => {
  const [loading, setLoading] = useState(true);
  const { name: methodName, ...formParams } = params;

  return (
    <>
      {loading && <Spinner big={false} wrapped={false} />}
      {findFeMethod(methodName)
        ? (
          <FeMethodLoader
            params={params}
            setLoading={setLoading}
            setResult={setResult}
            iframeRef={iframeRef}
            integrationAdditionalInfo={integrationAdditionalInfo}
          />
        ) : (
          <>
            <form action={url} method="post" target="cashier-frame" ref={formRef}>
              {Object.entries(formParams).map(([name, value]) => (
                <input type="hidden" name={name} value={value} key={name} />
              ))}
            </form>
            <iframe
              name="cashier-frame"
              className={`qd-cashier__frame${loading ? ' qd-cashier__frame--loading' : ''}`}
              onLoad={() => { setLoading(false); }}
              ref={iframeRef}
            />
          </>
        )}
    </>
  );
};

CashierFormFrameView.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.instanceOf(Object).isRequired,
  formRef: PropTypes.instanceOf(Object).isRequired,
  iframeRef: PropTypes.instanceOf(Object).isRequired,
  setResult: PropTypes.func.isRequired,
  integrationAdditionalInfo: PropTypes.instanceOf(Object).isRequired,
};

export default CashierFormFrameView;

// TODO: Very similar component in asia cashier and cashier actions
// Try to reuse the most simple one and less coupled to its usage
// I'll probably use this one, not a view one, but it auto submit the form
// To be rendered once we have a session.success
