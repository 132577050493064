export default (params) => {
  const event = document.createEvent('CustomEvent');

  event.initCustomEvent(
    'qdTrackData',
    true,
    true,
    {
      success: params.success,
      amt: params['psp_parameters.amount'],
      currency: params['psp_parameters.currency'],
      id: params.payment_transaction_id,
      an: params['psp_parameters.account_number'],
      ftd: params.first_time_transaction,
      code: params.error_code,
      qd: params['psp_parameters.quick_deposit'],
      domain: window.location.host,
    },
  );

  document.dispatchEvent(event);
};
