import { useState } from 'react';
import { CashierFormResult, CashierFormFrame } from './components';

const CashierFormContainer = (props) => {
  const [result, setResult] = useState();

  return (
    <div className="qd-cashier">
      {result ? (
        <CashierFormResult {...result} setResult={setResult} />
      ) : (
        <CashierFormFrame {...props} setResult={setResult} />
      )}
    </div>
  );
};

export default CashierFormContainer;
