import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { modalBack } from 'bv-helpers/location';
import { toCurrency } from 'bv-helpers/number';
import { refresh as refreshBalance } from 'bv-services/account-balance';
import CashierFormResultView from './cashier_form_result_view';
import sendTrackingEvent from './helpers/send_tracking_event';

const CashierFormResultContainer = (props) => {
  const {
    success: successStr,
    error_message: errorMessage,
    'psp_parameters.amount': amount,
    setResult,
  } = props;

  const success = successStr === 'true';

  useEffect(() => {
    if (success) refreshBalance();
    sendTrackingEvent(props);
  }, []);

  // Button callbacks
  const history = useHistory();

  const reload = () => {
    history.replace(amount ? `/quick_deposit/${amount}` : '/quick_deposit');
    setResult();
  };

  return (
    <CashierFormResultView
      type={success ? 'success' : 'error'}
      message={success ? (
        t('quick_deposit.success_message', { amount: toCurrency(amount) })
      ) : errorMessage}
      title={t(`quick_deposit.${success ? 'successful' : 'unsuccessful'}_deposit`)}
      iconProps={{ warning: !success, success }}
      actions={success ? [
        {
          primary: true,
          inverse: true,
          label: t('quick_deposit.continue'),
          onClick: modalBack,
        },
      ] : [
        {
          warning: true,
          label: t('quick_deposit.try_again'),
          onClick: reload,
        },
      ]}
    />
  );
};

CashierFormResultContainer.propTypes = {
  success: PropTypes.string.isRequired,
  error_message: PropTypes.string.isRequired,
  'psp_parameters.amount': PropTypes.string.isRequired,
  setResult: PropTypes.func.isRequired,
};

export default CashierFormResultContainer;
