import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Icon, Button } from 'bv-components';

const CashierFormResultView = ({
  type,
  message,
  iconProps,
  title,
  actions,
}) => (
  <div className={`qd-result qd-result--${type}`}>
    <Icon big {...iconProps} />
    <div className="qd-result__title">{title}</div>
    <div
      className="qd-result__message"
      dangerouslySetInnerHTML={{ __html: sanitize(message) }}
    />
    {actions.map((action) => <Button key={action.label} {...action} />)}
  </div>
);

CashierFormResultView.propTypes = {
  type: PropTypes.oneOf(['error', 'success']).isRequired,
  message: PropTypes.string,
  iconProps: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  actions: PropTypes.instanceOf(Array).isRequired,
};

CashierFormResultView.defaultProps = {
  message: '',
};

export default CashierFormResultView;
