import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { checkBrowserSupport, FeDepositForm } from 'SharedComponents/cashier/fe_deposit';

const FeMethodLoader = ({
  params,
  setLoading,
  setResult,
  iframeRef,
  integrationAdditionalInfo,
}) => {
  const [supported, setSupported] = useState();

  useEffect(() => {
    checkBrowserSupport(params)
      .then(setSupported)
      .catch(() => {
        setSupported(false);
      });
  }, []);

  if (supported === undefined) return null;

  return supported ? (
    <FeDepositForm
      setLoaded={() => setLoading(false)}
      setResult={setResult}
      iframeRef={iframeRef}
      integrationAdditionalInfo={integrationAdditionalInfo}
      isQuickDeposit
      {...params}
    />
  ) : (
    <Redirect to="/bv_cashier/deposit?first_modal=true" />
  );
};

FeMethodLoader.propTypes = {
  params: PropTypes.instanceOf(Object).isRequired,
  setLoading: PropTypes.func.isRequired,
  setResult: PropTypes.func.isRequired,
  iframeRef: PropTypes.instanceOf(Object).isRequired,
  integrationAdditionalInfo: PropTypes.instanceOf(Object).isRequired,
};

export default FeMethodLoader;
